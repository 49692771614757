<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          title="采购预案审批"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
          @click-right="onClickRight"
          ><template #right>
            <van-icon name="search" size="18" />
          </template>
        </van-nav-bar>
      </div>
      <!-- 排序 -->
      <div class="choose" v-if="!conditionsFlag && !show && !showexamination">
        <div class="choose-div">
          <span class="choosetext" @click="conditions(1)"
            ><span>排序</span><van-icon name="ascending" />
          </span>
          <span class="choosetext" @click="conditions(2)"
            ><span>筛选</span><van-icon name="filter-o"
          /></span>
        </div>
      </div>
    </div>
    <van-calendar
      v-if="show"
      v-model="show"
      type="range"
      @confirm="onConfirm"
      color="#1989fa"
      :min-date="minDate"
      :max-date="maxDate"
      :poppable="false"
      ref="calendar"
      :allow-same-day="true"
      :default-date="null"
    >
    </van-calendar>
    <van-calendar
      v-if="showexamination"
      v-model="showexamination"
      type="range"
      @confirm="onConfirmexamination"
      color="#1989fa"
      :min-date="minDate"
      :max-date="maxDate"
      :poppable="false"
      :allow-same-day="true"
      :default-date="null"
    />
    <div
      class="content"
      :class="[!conditionsFlag ? '' : 'contentbox']"
      v-if="!show && !showexamination"
    >
      <!-- 点击排序 -->
      <div class="contentsorting" v-if="conditionsFlag">
        <van-tabs v-model="active">
          <van-tab>
            <template #title> 排序<van-icon name="ascending"/></template>
            <div class="sorting">
              <div class="sortingtit">排序方式</div>
              <div class="reverse" @click="sorting('desc')">
                倒序排序
                <van-icon
                  name="success"
                  v-if="sort == 'desc'"
                  color="#2D8AFF"
                />
              </div>
              <div class="reverse" @click="sorting('asc')">
                正序排序
                <van-icon name="success" v-if="sort == 'asc'" color="#2D8AFF" />
              </div>
              <div class="sortingtit">排序属性</div>
              <div class="reverse" @click="sortProperties(1)">
                提报日期
                <van-icon name="success" v-if="sortType == 1" color="#2D8AFF" />
              </div>
              <div class="reverse" @click="sortProperties(2)">
                提报人<van-icon
                  name="success"
                  v-if="sortType == 2"
                  color="#2D8AFF"
                />
              </div>
              <div class="reverse" @click="sortProperties(3)">
                编制单位<van-icon
                  name="success"
                  v-if="sortType == 3"
                  color="#2D8AFF"
                />
              </div>
            </div>
          </van-tab>
          <van-tab>
            <template #title>筛选<van-icon name="filter-o"/></template>
            <div class="sorting">
              <div class="sortingtit">审批状态</div>
              <div class="sortingbut">
                <van-button
                  type="default"
                  size="small"
                  @click="sortingbut(1)"
                  :class="{ issfxed: statusType == 1 }"
                  >待审批</van-button
                >
                <van-button
                  type="default"
                  size="small"
                  @click="sortingbut(2)"
                  :class="{ issfxed: statusType == 2 }"
                  >已审批</van-button
                >
              </div>
              <div class="sortingtit">编制单位</div>
              <div class="reverse" @click="reverseOrder(0)">
                全部
                <van-icon name="success" v-if="compId == ''" color="#2D8AFF" />
              </div>
              <div
                class="reverse"
                @click="reverseOrder(item)"
                v-for="(item, index) in unit"
                :key="index"
              >
                {{ item.compShortName }}
                <van-icon
                  name="success"
                  v-if="compId == item.compId"
                  color="#2D8AFF"
                />
              </div>
              <div class="sortingtit">提报日期</div>
              <van-cell-group>
                <van-field
                  v-model="datavalue"
                  placeholder="请选择"
                  @focus="dateReported"
                />
              </van-cell-group>
              <div class="sortingtit">审批日期</div>
              <van-cell-group>
                <van-field
                  v-model="dataexamination"
                  placeholder="请选择"
                  @focus="dateReportedmexamination"
                />
              </van-cell-group>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 列表 -->
      <van-loading color="#1989fa" v-if="falg" class="levelV" />
      <van-empty
        description="没有需要审批的单据"
        v-if="formConfig.data.length == 0 && !conditionsFlag && !falg"
      />
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        success-text="刷新成功"
        v-if="!conditionsFlag && formConfig.data.length != 0"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <list
            :formConfig="formConfig"
            :signing="1"
            ref="far"
            @checked="checkedfun"
            @result="resultfun"
          />
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 其他页页尾 -->
    <div class="footer" v-if="conditionsFlag && !show && !showexamination">
      <div class="left" @click="onReset">重置</div>
      <div class="right" @click="onCancel">确定</div>
    </div>
    <!-- 列表页页尾 -->
    <div
      class="footerindex"
      v-if="!conditionsFlag && formConfig.data.length != 0 && statusType == 1"
    >
      <div>
        <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox>
      </div>
      <van-button type="info" size="small" @click="through">通过</van-button>
    </div>
  </div>
</template>
<script>
// import utils from '@/utils/filters'
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  data() {
    return {
      falg: true,
      compId: "",
      unit: [],
      choose: "",
      checked: false,
      result: [],
      loading: false,
      finished: false,
      isLoading: false,
      active: 0,
      conditionsFlag: false,
      sort: "desc",
      sortType: 1,
      statusType: 1,
      show: false,
      showexamination: false,
      datavalue: "",
      dataexamination: "",
      pageIndex: 1,
      pageSize: 10,
      submitBeginTime: "",
      submitEndTime: "",
      approveBeginTime: "",
      approveEndTime: "",
      minDate: "",
      maxDate: new Date(),
      month: "",
      dataV: "",
      formConfig: {
        data: [],
        columns: [
          {
            prop: "stockName",
            label: "采购方式",
          },
          {
            prop: "rmbamount",
            label: "概算金额(元)",
          },
          {
            prop: "contractTypeName",
            label: "合同类型",
          },
          {
            prop: "projname",
            label: "项目名称",
          },
        ],
        total: 0,
        url: "procurementDetails",
      },
    };
  },
  computed: {
    createBeginDate() {
      var date = new Date();
      date.setMonth(date.getMonth() - 3);
      date.toLocaleDateString();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
  },
  watch: {
    "$route.query": function(newVal) {
      if (newVal.type == 1) {
        this.falg = true;
        this.formConfig.data = [];
        this.pageIndex = 1;
        this.pageSize = 10;
        this.getList();
      }
    },
  },
  created() {
    this.getList();
    this.toCompInfo();
    this.minDate = new Date(this.createBeginDate);
  },
  methods: {
    formatDate(date) {
      if (String(date.getMonth() + 1).length == 1) {
        this.month = "0" + (date.getMonth() + 1);
      } else {
        this.month = date.getMonth() + 1;
      }
      if (String(date.getDate()).length == 1) {
        this.dataV = "0" + date.getDate();
      } else {
        this.dataV = date.getDate();
      }
      return `${date.getFullYear()}/${this.month}/${this.dataV}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.submitBeginTime = this.formatDate(start);
      this.submitEndTime = this.formatDate(end);
      this.datavalue = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    dateReported() {
      this.show = true;
    },
    onConfirmexamination(date) {
      const [start, end] = date;
      this.showexamination = false;
      this.approveBeginTime = this.formatDate(start);
      this.approveEndTime = this.formatDate(end);
      this.dataexamination = `${this.formatDate(start)} - ${this.formatDate(
        end
      )}`;
    },
    dateReportedmexamination() {
      this.showexamination = true;
    },
    sortingbut(o) {
      this.statusType = o;
    },
    sortProperties(o) {
      this.sortType = o;
    },
    async toCompInfo() {
      let data = await this.$api.ec.toCompInfo();
      this.unit = data.data;
    },
    reverseOrder(o) {
      if (o == 0) {
        this.compId = "";
      } else {
        this.compId = o.compId;
      }
    },
    sorting(o) {
      this.sort = o;
    },
    through() {
      if (this.$refs.far.result.length > 0) {
        Dialog.confirm({
          title: "审批",
          message: "是否同意",
        })
          .then(() => {
            this.auditProjectList();
          })
          .catch(() => {
            // on cancel
          });
      } else {
        Toast.fail("请至少选择一个审核内容");
      }
    },
    checkAll() {
      if (this.checked === true) {
        this.$refs.far.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.far.$refs.checkboxGroup.toggleAll(false);
      }
    },
    checkedfun(data) {
      this.checked = data;
    },
    resultfun(data) {
      this.result = data;
    },
    conditions(o) {
      if (o == 1) {
        this.active = 0;
      } else {
        this.active = 1;
      }
      this.conditionsFlag = true;
    },
    onClickLeft() {
      if (this.show || this.showexamination) {
        this.show = false;
        this.showexamination = false;
      } else {
        window.location.replace(process.env.VUE_APP_INDEX);
      }
    },
    onClickRight() {
      this.$router.push({ name: "procurementsearch" });
    },
    // 刷新成功
    onRefresh() {
      this.pageIndex = 1;
      this.pageSize = 10;
      this.approvalListfresh();
    },
    onLoad() {
      this.getList();
    },
    onCancel() {
      this.falg = true;
      this.conditionsFlag = false;
      this.formConfig.data = [];
      this.pageIndex = 1;
      this.getList();
    },
    onReset() {
      this.falg = true;
      (this.pageIndex = 1),
        (this.pageSize = 10),
        // (this.statusType = 1),
        (this.sort = "desc"),
        (this.sortType = 1),
        (this.submitBeginTime = ""),
        (this.submitEndTime = "");
      (this.approveBeginTime = ""), (this.approveEndTime = "");
      this.compId = "";
      this.datavalue = "";
      this.dataexamination = "";
      this.formConfig.data = [];
      this.conditionsFlag = false;
      this.getList();
    },
    async approvalListfresh() {
      const params = {
        pageIndex: 1,
        pageSize: 10,
        statusType: this.statusType,
      };
      let data = await this.$api.ec.getList(params);
      this.isLoading = false;
      this.formConfig.data = data.data;
      this.formConfig.data.map((item) => {
        item.createUser = item.submitUserName;
        item.supplierName = item.projtopic;
        item.id = item.projectId;
        // item.rmbamount =utils.numberFormat(item.rmbamount,2)
      });
    },
    // 采购预案待审批列表
    async getList() {
      const params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        statusType: this.statusType,
        sort: this.sort,
        sortType: this.sortType,
        submitBeginTime: this.submitBeginTime.replaceAll("/", "-"),
        submitEndTime: this.submitEndTime.replaceAll("/", "-"),
        approveBeginTime: this.approveBeginTime.replaceAll("/", "-"),
        approveEndTime: this.approveEndTime.replaceAll("/", "-"),
        compId: this.compId,
      };
      let data = await this.$api.ec.getList(params);
      this.falg = false;
      if (data) {
        if (data.data) {
          data.data.forEach((item) => {
            item.createUser = item.submitUserName;
            item.supplierName = item.projtopic;
            // item.rmbamount =utils.numberFormat(item.rmbamount,2)
          });
          if (data.data.length != 0) {
            this.pageIndex++;
            this.formConfig.data = [...this.formConfig.data, ...data.data];
          }
          if (data.data.length != 10) {
            this.loading = true;
            this.finished = true;
          } else {
            this.loading = false;
            this.finished = false;
          }
        }
        if (!data.data) {
          this.loading = true;
          this.finished = true;
        }
      }
    },
    async auditProjectList() {
      let result = this.$refs.far.result;
      if (result.length === 1) {
        this.choose = result[0].id.toString();
      } else {
        result.map((item, index) => {
          if (index === 0) {
            this.choose = item.id;
          } else {
            this.choose = this.choose + "," + item.id;
          }
        });
      }
      let data = await this.$api.ec.auditProjectList(this.choose);
      if (data) {
        this.falg = true;
        this.formConfig.data = [];
        this.pageIndex = 1;
        Toast.success("提交成功");
        this.getList();
      }
    },
  },
};
</script>
